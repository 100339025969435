<template>
  <div>
    <div
      v-if="emailConversationList.length"
      :class="{ 'at-67': emailConversationList.length > 3 }"
      @scroll="handleScroll"
    >
      <div v-for="(data, index) in emailConversationList" :key="index">
        <blockquote :class="'speech-bubble ' + data.direction">
          <span>
            <i
              v-if="
                currentUserRole === Role.systemAdmin ||
                currentUserRole === Role.customerAdmin
              "
              class="fas fa-trash ml-3 qw-0"
              @click="ModalCallBack(data.message_id, data.subject)"
            >
            </i>
          </span>
          <label class="subject">{{ data.subject }}</label>
          <p v-html="getBody(data.body)"></p>

          <span
            class="attachment-item mt-3"
            v-for="(attachment, index) in data.email_attachment"
            :key="index"
          >
            <a v-if="attachment" @click="downloadFile(attachment)"
              ><i class="fa fa-paperclip mr-1" aria-hidden="true"></i
              >{{ attachment.file_name }}</a
            >
            <a v-if="attachment" download :href="attachToken(attachment.attachment_id)"
              ><i class="fas fa-download ml-1"></i></a
            >
          </span>
          <label class="note"
            ><time>{{ data.load_datetime | formatDate }}</time>
          </label>
        </blockquote>
      </div>
      <PreviewModal
        modalTitle="Document Preview"
        modalColor="primary"
        :buttons="['OK']"
        size="lg"
        :isShowPopup="previewModal.isShowPopup"
        :modalCallBack="previewModalCallBack"
        :isPreview="previewModal.isPreview"
        :iframe="iframe"
        :closeOnBackdrop="false"
      />
    </div>
    <p v-else class="text-center text-gray h5">No Email Conversation Found</p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Role, getScope, appendAccessToken } from "@/helpers/helper";
import PreviewModal from "../../components/reusable/PreviewModal.vue";
import pdf from "vue-pdf";
import { BASE_URL } from "@/service_urls";
export default {
  name: "EmailConversation",
  props: {
    email_subject: {
      type: String,
      default: null,
    },
    recruiter_message: {
      type: Boolean,
      default: false,
    },
  },
  components: { PreviewModal },
  data() {
    return {
      url: null,
      Role,
      currentUserRole: getScope(),
      previewModal: {
        isShowPopup: false,
        isPreview: false,
      },
      iframe: {
        url:null,
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      mailCount: 5,
      BASE_URL:BASE_URL
    };
  },
  computed: {
    ...mapGetters(["getEmailConversation"]),
    isCandidate() {
      return (
        this.currentUserRole === Role.customerCandidate ||
        this.currentUserRole === Role.systemCandidate
      );
    },
    EmailConversation() {
      if (this.isCandidate) {
        return this.getEmailConversation
          ? _.filter(this.getEmailConversation, (message) => {
              let subject = "Re: " + this.email_subject;
              if (
                message.subject === this.email_subject ||
                message.subject === subject
              ) {
                return message;
              }
            })
          : [];
      } else {
        if (this.recruiter_message) {
          return this.getEmailConversation
            ? _.filter(this.getEmailConversation, (message) => {
                let subject = this.email_subject.split(": ")[1]
                  ? this.email_subject.split(": ")[1]
                  : "Re: " + this.email_subject;
                if (
                  message.subject === this.email_subject ||
                  message.subject === subject
                ) {
                  return message;
                }
              })
            : [];
        } else return this.getEmailConversation;
      }
    },
    emailConversationList() {
      return this.EmailConversation.slice(0, this.mailCount);
    },
  },
  methods: {
    ...mapActions(["downloadAttachment"]),
    downloadFile(attachment) {
      let attachment_id = attachment.attachment_id;
      let appendAction = this.downloadAttachment({ attachment_id });
      Promise.all([appendAction]).then((res) => {
        let url = appendAccessToken(res[0]);
        this.iframe.url = url;
        let file_type = attachment.file_name.split(".").pop();
        if (
          file_type === "png" ||
          file_type === "jpg" ||
          file_type === "jpeg" ||
          file_type === "gif"
        ) {
          this.iframe.loaded = false;
          this.iframe.ispdf = false;
          this.iframe.isImg = true;
          this.iframe.src = url;
          this.previewModal.isPreview = true;
          this.previewModal.isShowPopup = true;
        } else if (file_type === "pdf") {
          this.iframe.isImg = false;
          this.iframe.loaded = false;
          this.iframe.pdf.ispdf = true;
          this.iframe.pdf.src = pdf.createLoadingTask(url);
          setTimeout(() => {
            this.iframe.pdf.src.promise.then((pdf) => {
              this.iframe.pdf.numPages = pdf.numPages;
            });
          }, 100);
          this.previewModal.isPreview = true;
          this.previewModal.isShowPopup = true;
        } else if (
          file_type === "txt" ||
          file_type === "eps" ||
          file_type === "tif" ||
          file_type === "tief" ||
          file_type === "raw"
        ) {
          this.showToast({
            class: "bg-danger text-white",
            message: "The selected file type is not available for Preview.",
          });
          this.iframe.src = "";
          this.iframe.loaded = false;
          this.iframe.isImg = false;
          this.iframe.pdf.ispdf = false;
          this.previewModal.isPreview = false;
          this.previewModal.isShowPopup = false;
        } else {
          this.iframe.isImg = false;
          this.iframe.ispdf = false;
          this.iframe.loaded = true;
          let src = encodeURIComponent(url);
          this.iframe.src =
            "https://view.officeapps.live.com/op/embed.aspx?src=" + src;
          this.previewModal.isPreview = true;
          this.previewModal.isShowPopup = true;
        }
      });
    },
    attachToken(attachment_id) {
      let _base_domain = `${window.location.origin}/api/v1`;
      return appendAccessToken(`${_base_domain}/communication/email/file?attachment_id=${attachment_id}`)
    },
    previewModalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    ModalCallBack(delete_id, subject) {
      this.$emit("conversationModalCallBack", delete_id, subject);
    },
    handleScroll(event) {
      if (
        event.srcElement.offsetHeight + event.srcElement.scrollTop >=
        event.srcElement.scrollHeight
      ) {
        this.mailCount += 5;
      }
    },
    getBody(data) {
      let body;
      if (data.includes("<p>")) {
        body = data.replaceAll('<p>', '<p class="mb-0">');
      }else if (data.includes('\r\n\r\n')) {
        body = data.replaceAll('\r\n\r\n', '<p class="mb-0"><br></p>');
      }else {
        body = data;
      }
      return body;
    },
  },
};
</script>

<style lang="scss" scoped>

$primary: map-get($theme-colors, primary);
$secondary: map-get($theme-colors, secondary);
/// REQUIRED
$gutter: 20px;
$caret-unit: 25px;
$bubble-bg: #5dacb5;
$bubble-bg-alt: #C0C0C0;
$bubble-color: white;
$cite-color: rgb(37, 37, 37);

@function opposite-direction($directions) {
  $opposite-directions: ();
  $direction-map: (
    "top": "bottom",
    "right": "left",
    "bottom": "top",
    "left": "right",
    "center": "center",
    "ltr": "rtl",
    "rtl": "ltr",
  );

  @each $direction in $directions {
    $direction: to-lower-case($direction);

    @if map-has-key($direction-map, $direction) {
      $opposite-directions: append(
        $opposite-directions,
        unquote(map-get($direction-map, $direction))
      );
    } @else {
      @warn "No opposite direction can be found for `#{$direction}`. Direction omitted.";
    }
  }

  @return $opposite-directions;
}

/// Unit Removal
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

/// Triangle Mixin
/// ==========================================================

@mixin caret($point, $border-width, $color) {
  $opposite: opposite-direction($point);
  border: $border-width solid transparent;
  border-#{$opposite}: $border-width solid $color;
  border-#{$point}: 0;
  height: 0;
  width: 0;
}

/// Component
.speech-bubble {
  // filter: drop-shadow(-1px -1px 2px rgba(black, 0.1))
  //   drop-shadow(1px 2px 2px rgba(black, 0.15));
  margin: 1rem;
  margin-bottom: ($gutter * 2);
  padding: 1.5rem 2rem;
  position: relative;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  color: #383838;

  &::before {
    @include caret(bottom, ($caret-unit / 2), $bubble-bg);
    border-top-width: $caret-unit;
    content: "\0020";
    display: block;
    position: absolute;
    left: 47px;    
    bottom: -27px;
    border-top-color: $bubble-color;
    transform-origin: center;
    transform: rotate(90deg) skew(-(strip-unit($caret-unit)) + deg)
      translateY($caret-unit / 1.5);
  }

  &::after {
    @include caret(bottom, ($caret-unit / 2), $bubble-bg);
    border-top-width: $caret-unit;
    content: "";
    display: block;
    position: absolute;
    left: 44.9px;    
    bottom: -21px;
    border-top-color: $bubble-color;
    transform-origin: center;
    transform: rotate(90deg) skew(-(strip-unit($caret-unit)) + deg)
      translateY($caret-unit / 1.5);
  }
}
.speech-bubble .subject {
  font-weight: bold;
  clear: both;
  font-style: italic;
}
.speech-bubble .note {
  position: absolute;
  bottom: -2rem;
  left: 4.5rem;
  font-size: 0.6rem;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.5px;
  color: $cite-color;

  time {
    color: #000;
    margin-right: 5px;
  }
}
.I {
  border: 2px solid $bubble-bg-alt;
}
.O {
  border: 2px solid $bubble-bg;
}
.I::before {
  border-top: 12.5px solid $bubble-bg-alt;
      border-top-width: 25px;
}
.O::before {
  border-top: 12.5px solid $bubble-bg;
      border-top-width: 25px;
}
.attachment-item a {
  cursor: pointer;
  white-space: nowrap;
  margin: 8px;
  text-decoration: underline;
}
.qw-0 {
  float: right;
  cursor: pointer;
}
.at-67 {
  height: 535px;
  overflow: auto;
}
</style>
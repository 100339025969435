<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      :centered="true"
      size="sm"
    >
      <template #header>
        <h6 class="modal-title">Delete Conversation</h6>
        <CButtonClose @click="modalCallBack" class="text-black" />
      </template>
      <template #footer>
        <CButton form="reasons" color="secondary" @click="modalCallBack"
          >No</CButton
        >
        <CButton
          form="reasons"
          color="primary"
          type="Submit"
          @click="deleteConversation"
          >Yes</CButton
        >
      </template>
      <div>
        <p>
          Are you sure to delete this <br />
          <strong>
            {{ email_subject }}
            </strong
            >?
        </p>
      </div>
    </CModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EmailConversation",
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    delete_id: {
      type: Number,
      default: null,
    },
    email_subject: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["deleteEmailConversation"]),
    modalCallBack() {
      this.$emit("deleteModalCallBack", "email");
    },
    deleteConversation() {
        let appendAction = this.deleteEmailConversation({ deleteId: this.delete_id });
        Promise.all([appendAction]).then((res) => {
            if (res) {
                this.$emit("deleteModalCallBack");
            }
        });
    },
  },
  mounted() {
    this.$emit("emailModalCallBack", "email");
  },
};
</script>
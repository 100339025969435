<template>
  <div class="email-modal">
    <CModal
      class="text-black"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      color="primary"
      size="lg"
    >
      <template #header>
        <h6 v-if="mail.mail_id" class="modal-title white">
          Email Conversation - {{ mail.mail_id }}
        </h6>
        <h6 class="modal-title white"></h6>
        <CButtonClose
          :disabled="isDisable"
          @click="modalCallBack"
          class="text-black"
        />
      </template>
      <template #footer>
        <CButton
          type="button"
          color="primary"
          variant="outline"
          class="px-4"
          :disabled="isDisable"
          @click="modalCallBack"
          >Close</CButton
        >
      </template>
      <ValidationObserver ref="mailform" v-slot="{ handleSubmit }">
        <CRow class="row mb-3">
          <div @click="ShowHideNewMessage()" class="messageicon text-primary">
            <span v-if="!isShowCompose"
              ><i class="fa fa-paper-plane"></i>New email</span
            >
            <span v-if="isShowCompose"><i class="fa fa-minus"></i>Hide</span>
          </div>
        </CRow>
        <transition name="fade">
          <form
            @submit.prevent="handleSubmit(SendOutboundMail)"
            name="mailform"
            v-if="isShowCompose"
            :style="
              isShowSideBar && windowWidth
                ? 'margin-right: 300px;'
                : 'margin-right: 0px;'
            "
          >
            <div class="p-2">
              <CRow class="row mb-3" v-if="emailBox">
                <label class="col-lg-12 col-md-12 required">Email</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <i
                    v-if="
                      [
                        Role.systemAdmin,
                        Role.customerAdmin,
                        Role.customerRecruiter,
                      ].includes(currentUserRole)
                    "
                    class="fa fa-users cursor-pointer"
                    aria-hidden="true"
                    @click="ShowHideSideBar"
                  ></i>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <TextInput
                      name="mail_id"
                      :value="mail.mail_id"
                      :error="errors[0]"
                      @change="handleInput"
                      :disabled="false"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <label class="col-lg-12 col-md-12 required">Subject</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <TextInput
                      name="subject"
                      :value="mail.subject"
                      @change="handleInput"
                      :error="errors[0]"
                      :disabled="disabled"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <label class="col-lg-12 col-md-12 ng-4">Message</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="editor-container">
                    <vue-editor
                      class="message-body"
                      name="message"
                      ref="editor"
                      :value="mail.message"
                      :text-change="textChange"
                      :editor-toolbar="customToolbar"
                      @input="handleEditor"
                    ></vue-editor>
                  </div>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <label class="col-lg-12 col-md-12">Attachment</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <input
                    name="upload-document"
                    type="file"
                    ref="file"
                    @change="handleFile"
                    multiple
                  />
                </div>
              </CRow>
              <CRow
                class="
                  ml-2
                  mr-2
                  d-flex
                  align-items-center
                  documentScrolling
                  document-table
                "
                v-if="files.length !== 0"
              >
                <code
                  v-for="(data, index) in files"
                  :key="index"
                  class="text-primary"
                >
                  <span>{{ data.name }}</span>
                  <span class="nx-90 cursor-pointer">
                    <i
                      class="fas fa-trash ml-3"
                      @click="deleteDocuments(index)"
                    ></i>
                  </span>
                </code>
              </CRow>
              <CRow class="row mb-3">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <CButton
                    type="button"
                    v-if="btnHideSend"
                    color="primary"
                    class="float-right"
                    @click="sendOutboundMail"
                    :disabled="btnText == 'Sending...'"
                  >
                    <CSpinner
                      v-if="btnText == 'Sending...'"
                      class="spinner-border-sm text-white m-1"
                    />
                    {{ btnText }}</CButton
                  >
                  <CButton
                    type="button"
                    v-if="btnHideNext"
                    color="primary"
                    class="float-right"
                    @click="openCommentModal"
                    >Next</CButton
                  >
                </div>
              </CRow>
            </div>
          </form>
        </transition>
        <transition name="slide-fade">
          <div class="side-bar" v-if="isShowSideBar">
            <div class="side-backdrop" @click="ShowHideSideBar"></div>
            <div class="side-panel">
              <div class="email-header">
                <CDropdown placement="bottom-end" :show.sync="isShowDrop">
                  <template #toggler>
                    <span class="material-icons dot-icon cursor-pointer">
                      more_vert
                    </span>
                  </template>
                  <div class="dropdown-item-group">
                    <CLink
                      class="dropdown-item cursor-pointer"
                      @click="getCustomers(false)"
                      >User</CLink
                    >
                    <CLink
                      class="dropdown-item cursor-pointer"
                      @click="getCustomers(true)"
                      >Contact</CLink
                    >
                    <CLink
                      v-if="
                        [Role.customerAdmin, Role.customerRecruiter].includes(
                          currentUserRole
                        )
                      "
                      class="dropdown-item cursor-pointer"
                      @click="getCandidates"
                      >Candidate</CLink
                    >
                  </div>
                </CDropdown>
                <TextInput
                  name="email-search-bar"
                  class="w-100"
                  @input="handleSearchTerm"
                  placeholder="Search by email and name"
                />
              </div>
              <div
                class="body"
                v-if="
                  userEmailAddress.length || getCandidateEmailAddress.length
                "
              >
                <div class="user-list" v-if="userEmailAddress.length">
                  <div
                    v-for="(data, index) in userEmailAddress"
                    :key="index"
                    class="email-list"
                  >
                    <span
                      class="user-email"
                      @click="selectUserEmail(data.email)"
                    >
                      <div class="user-icon">
                        <i
                          class="fas fa-user-circle"
                          :class="data.contact ? 'contact' : 'user'"
                        ></i>
                      </div>
                      <div class="user-detail pl-3 pr-3">
                        <label class="name mb-0">{{ data.full_name }}</label>
                        <p class="mail mb-0">{{ data.email }}</p>
                      </div>
                    </span>
                  </div>
                </div>
                <div
                  class="candidate-list"
                  v-if="getCandidateEmailAddress.length"
                >
                  <div
                    v-for="(data, index) in getCandidateEmailAddress"
                    :key="index"
                    class="email-list"
                  >
                    <span
                      class="user-email"
                      @click="selectUserEmail(data.email)"
                    >
                      <div class="user-icon">
                        <i class="fas fa-user-circle candidate"></i>
                      </div>
                      <div class="user-detail pl-3 pr-3">
                        <label class="name mb-0">{{ data.full_name }}</label>
                        <p class="mail mb-0">{{ data.email }}</p>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div class="body" v-else>
                <div class="d-flex align-items-center h-100">
                  <div class="container text-center">
                    <h4>
                      No Data Found<i
                        class="fas fa-ban ml-1"
                        :style="{ color: '#e55353' }"
                      ></i>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </ValidationObserver>
      <EmailConversation
        v-if="!emailBox"
        :email_subject="email_subject"
        :recruiter_message="recruiter_message"
        @conversationModalCallBack="conversationModalCallBack"
      />
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import { mapGetters, mapActions } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import EmailConversation from "@/containers/Communication/EmailConversation";
import { Role, getScope } from "@/helpers/helper";
import { VueEditor } from "vue2-editor";
extend("required", { ...required, message: "This field is required" });
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    email_id: {
      type: String,
      default: null,
    },
    sender: {
      type: String,
      default: null,
    },
    sender_name: {
      type: String,
      default: null,
    },
    customer_user_id: {
      type: Number,
      default: null,
    },
    email_subject: {
      type: String,
      default: null,
    },
    job_id: {
      type: Number,
      default: null,
    },
    candidate_uid: {
      type: Number,
      default: null,
    },
    currentPage: {
      type: String,
      default: null,
    },
    alternate_email: {
      type: String,
      default: null,
    },
    recruiter_message: {
      type: Boolean,
      default: false,
    },
    emailBox: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TextInput,
    TextareaInput,
    EmailConversation,
    VueEditor,
  },
  data() {
    return {
      Role,
      currentUserRole: getScope(),
      mail: {
        sender: "",
        mail_id: this.email_id,
        subject: "",
        message: "",
      },
      btnText: "Send Email",
      files: [],
      btnHideSend: true,
      btnHideNext: false,
      messageId: null,
      isDisable: false,
      subject: null,
      iconBold: false,
      iconItalic: false,
      messageData: null,
      savedRange: null,
      isShowCompose: false,
      blank: false,
      customToolbar: [["bold", "italic"]],
      textChange: "source",
      isShowSideBar: false,
      searchTerm: "",
      isShowDrop: false,
    };
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getUserEmailAddress",
      "getWindowWidth",
      "getCandidateEmailAddress",
    ]),
    isCandidate() {
      return (
        this.currentUserRole === Role.customerCandidate ||
        this.currentUserRole === Role.systemCandidate
      );
    },
    getUserName() {
      let firstname = this.getUser.first_name;
      let lastname = this.getUser.surname;

      if (firstname && lastname) {
        firstname = firstname
          .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
          .replace(/\s/g, "");

        lastname = lastname
          .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
          .replace(/\s/g, "");

        return firstname + " " + lastname;
      }
      return false;
    },
    getUserEmail() {
      return this.getUser.full_name;
    },
    getUserId() {
      return this.getUser.id;
    },
    disabled() {
      if (
        this.currentUserRole === this.Role.customerCandidate ||
        this.currentUserRole === this.Role.systemCandidate
      ) {
        let subject = this.email_subject.split(": ")[1]
          ? this.email_subject
          : "Re: " + this.email_subject;
        this.mail.subject = subject;
        return true;
      } else {
        if (this.recruiter_message) {
          let subject = this.email_subject.split(": ")[1]
            ? this.email_subject.split(": ")[1]
            : this.email_subject;
          this.mail.subject = subject;
          return true;
        } else return false;
      }
    },
    windowWidth() {
      if (this.getWindowWidth > 991) {
        return true;
      }
      return false;
    },
    userEmailAddress() {
      return this.getUserEmailAddress.filter((row) => {
        const email = row.email.toLowerCase();
        const name = row.full_name.toLowerCase();
        const searchTerm = this.searchTerm.toLowerCase();

        return email.includes(searchTerm) || name.includes(searchTerm);
      });
    },
  },
  methods: {
    ...mapActions([
      "getConversation",
      "sendOutboundEmail",
      "showToast",
      "getEmailConversation",
      "fetchOrgLocation",
      "fetchAllUserEmailSeacrhTerm",
      "fetchAllCustomerContactUsersEmail",
    ]),
    handleInput(name, value) {
      Vue.set(this.mail, name, value);
    },
    handleEditor(data) {
      Vue.set(this.mail, "message", data);
    },
    handleSearchTerm(name, value) {
      this.searchTerm = value;
      if (
        [this.Role.customerAdmin, this.Role.customerRecruiter].includes(
          this.currentUserRole
        )
      )
        this.fetchAllUserEmailSeacrhTerm({ searchTerm: value });
    },
    handleFile(event) {
      for (var i = 0; i < event.target.files.length; i++) {
        this.files.push(event.target.files[i]);
      }
      this.$refs.file.value = null;
    },
    conversationModalCallBack(delete_id, subject) {
      this.$emit("conversationModalCallBack", delete_id, subject);
    },
    openCommentModal() {
      this.$emit("openCommentModal", this.messageId, "email");
      this.isDisable = false;
    },
    modalCallBack() {
      this.$emit("emailModalCallBack", "email");
    },
    async sendOutboundMail() {
      const isValid = await this.$refs.mailform.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.btnText = "Sending...";
      let sender;
      let sender_name;
      let customer_user_id;
      if (
        this.currentUserRole === this.Role.customerCandidate ||
        this.currentUserRole === this.Role.systemCandidate
      ) {
        sender = this.sender;
        sender_name = this.sender_name;
        customer_user_id = this.customer_user_id;
      } else {
        sender = this.getUserEmail;
        sender_name = this.getUserName;
        customer_user_id = this.getUserId;
      }
      let appendAction = this.sendOutboundEmail({
        sender: sender,
        sender_name: sender_name,
        customer_user_id: customer_user_id,
        email_to: this.mail.mail_id,
        subject: this.mail.subject,
        message: this.mail.message,
        job_id: this.job_id,
        candidate_uid: this.candidate_uid,
        files: this.files,
      });
      Promise.all([appendAction]).then((res) => {
        if (res) {
          if (
            res == 500 ||
            res == 422 ||
            res == 400 ||
            res == 401 ||
            res == 403 ||
            res == 404 ||
            res == 322 ||
            res == 502
          ) {
            this.btnText = "Send Email";
          } else if (
            (this.currentUserRole === this.Role.systemAdmin &&
              this.$router.currentRoute.name == "Candidate") ||
            (this.currentUserRole === this.Role.customerAdmin &&
              this.$router.currentRoute.name == "Candidate") ||
            (this.currentUserRole === this.Role.systemRecruiter &&
              this.$router.currentRoute.name == "Candidate") ||
            (this.currentUserRole === this.Role.customerRecruiter &&
              this.$router.currentRoute.name == "Candidate")
          ) {
            this.btnText = "Send Email";
          } else if (
            (this.currentUserRole === this.Role.systemAdmin &&
              this.$router.currentRoute.name === "Job List Preview") ||
            (this.currentUserRole === this.Role.customerAdmin &&
              this.$router.currentRoute.name === "Job List Preview") ||
            (this.currentUserRole === this.Role.systemRecruiter &&
              this.$router.currentRoute.name === "Job List Preview") ||
            (this.currentUserRole === this.Role.customerRecruiter &&
              this.$router.currentRoute.name === "Job List Preview")
          ) {
            this.btnHideSend = false;
            this.btnHideNext = true;
            this.isDisable = true;
            this.messageId = res[0]?.data.split("-")[1];
          } else if (
            this.currentUserRole === this.Role.customerCandidate ||
            this.currentUserRole === this.Role.systemCandidate
          ) {
            this.btnText = "Send Email";
          } else if (this.recruiter_message) {
            this.btnText = "Send Email";
          } else {
            this.btnText = "Send Email";
          }
          //Clear Control
          this.$refs.mailform.reset();
          if (this.emailBox) this.mail.mail_id = null;
          this.mail.subject = this.disabled ? this.mail.subject : null;
          this.mail.message = null;
          this.$refs.file.value = null;
          this.files = []; //Clear for every selection
          let sender;
          if (
            this.currentUserRole === this.Role.customerCandidate ||
            this.currentUserRole === this.Role.systemCandidate
          ) {
            sender = this.sender;
          } else {
            sender = this.getUserEmail;
          }
          this.getEmailConversation({
            email: this.mail.mail_id,
            sender: sender,
          });
        }
      });
    },
    ShowHideNewMessage() {
      this.isShowCompose = !this.isShowCompose;
      this.$nextTick(
        function () {
          if (this.$refs.editor) {
            this.$refs.editor.$el.lastElementChild.firstElementChild.classList.add(
              "form-control"
            );
          }
        }.bind(this)
      );
    },
    ShowHideSideBar() {
      this.isShowSideBar = !this.isShowSideBar;
      if (this.isShowSideBar) this.fetchAllCustomerContactUsersEmail(null);
      this.searchTerm = "";
      this.$store.commit("SET_CANDIDATE_EMAIL_ADDRESS", []);
    },
    deleteDocuments(index) {
      this.files.splice(index, 1);
      if (!this.files.length) {
        this.$refs.file.value = null;
      }
    },
    selectUserEmail(email) {
      this.mail.mail_id = email;
      this.isShowSideBar = false;
    },
    getCustomers(data) {
      this.fetchAllCustomerContactUsersEmail(data);
      this.$store.commit("SET_CANDIDATE_EMAIL_ADDRESS", []);
      this.isShowDrop = false;
    },
    getCandidates() {
      this.fetchAllUserEmailSeacrhTerm({ searchTerm: null });
      this.$store.commit("SET_USER_EMAIL_ADDRESS", []);
      this.isShowDrop = false;
    },
  },
  mounted() {
    // get conversation of particular user or candidate
    if (this.emailBox == false) {
      let sender;
      if (
        this.currentUserRole === this.Role.customerCandidate ||
        this.currentUserRole === this.Role.systemCandidate
      ) {
        sender = this.sender;
      } else {
        sender = this.getUserEmail;
      }
      this.getEmailConversation({ email: this.email_id, sender: sender });
    }

    // default hide
    if (!this.mail.mail_id) this.ShowHideNewMessage();

    // get organization location
    if (
      [this.Role.customerAdmin, this.Role.customerRecruiter].includes(
        this.currentUserRole
      )
    )
      this.fetchOrgLocation();
  },
};
</script>
<style lang="scss" scoped>
$primary: map-get($theme-colors, primary);
.white {
  color: white;
  margin-right: 6px;
  margin-top: 2px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave {
  opacity: 1;
}
.messageicon {
  border-radius: 6px;
  padding-left: 3px;
  padding-right: 3px;
  margin-top: -9px;
  margin-left: 25px;
  cursor: pointer;
  color: #9e9e9e;
  font-weight: 600;
}
.messageicon i {
  margin-right: 5px;
}
code {
  padding: 0px 6px 0px 6px;
  margin: 4px;
  border-radius: 3px;
  background: #eee;
}
.nx-90 {
  margin-bottom: 6px;
}
.ng-4 {
  position: absolute;
}

.fa-users {
  position: absolute;
  right: 25px;
  top: 10px;
  color: #a7a6a6f2;
}

.side-bar {
  .side-panel {
    width: 100%;
    top: 0;
    right: 0;
    height: 100%;
    background-color: #f5f5f561;
    position: absolute;
    padding: 20px;
    z-index: 30;

    .email-header {
      .dot-icon {
        position: absolute;
        top: 6px;
        right: 0px;
        color: #9e9e9e;
        font-size: 20px;
      }
    }
    .header {
      align-items: center;
    }
    .body {
      overflow: auto;
      height: 97%;

      .user-list,
      .candidate-list {
        margin-top: 5px;

        .email-list {
          padding: 5px;
          cursor: pointer;

          &:hover {
            background-color: #9a9a9a2b;
          }

          .user-email {
            align-items: center;
            display: flex;
            color: #768192;

            .user-icon {
              .fa-user-circle {
                font-size: 25px;
                position: relative;
                top: 2px;
              }

              .candidate {
                color: #d4d4d4;
              }

              .user {
                color: #ff080873;
              }

              .contact {
                color: #93beff;
              }
            }
          }

          .user-detail {
            display: block;
            text-align: left;

            .name {
              font-size: 12px;
            }
            .mail {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
  .side-backdrop {
    background-color: #0000000f;
    width: 62.2222222%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
}

.slide-fade-enter-active {
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.05s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(5px);
  opacity: 0;
}

@media screen and (min-width: 992px) {
  .side-panel {
    width: 300px !important;
  }
}
@media screen and (max-width: 992px) {
  .side-panel {
    background-color: #fff !important;
  }
}
.empty {
  display: none;
}
</style>

<style lang="scss">
.message-body {
  .ql-toolbar,
  .ql-container {
    border: none !important;
  }
  .ql-toolbar {
    position: relative;
    top: -5px;
  }
  .ql-container {
    font-family: inherit;
  }
  .ql-toolbar .ql-formats {
    float: right;
    margin: 0 !important;
  }

  .ql-snow .ql-stroke {
    stroke: #a7a6a6f2 !important;
  }
  .ql-toolbar .ql-formats .ql-active,
  .ql-toolbar .ql-formats button:focus,
  .ql-toolbar .ql-formats button:hover,
  .ql-toolbar .ql-formats .ql-active .ql-stroke {
    stroke: #085d66e6 !important;
  }
  .ql-container .ql-editor {
    min-height: 130px;
    font-size: 0.875rem;
  }
  .ql-toolbar .ql-formats button svg {
    max-width: 18px;
    max-height: 18px;
  }
}
input[name="email-search-bar"] {
  padding: 10px;
  font-size: 12px;
}
.email-header .show .show {
  margin-top: 10px !important;
}
</style>
